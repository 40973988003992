.App {
    text-align: center;
}

.App-logo {
    
    height: 5em;
}

/* @media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
} */

.App-header {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.main_container {
    align-items: center;
    justify-content: center;
}
@media (max-width: 1000px) {
.logo_container {
    font-family: "Helvetica",  Sans-serif;
    max-width:fit-content;
    background-color: #ffff;
    border-color: #819bd3;
    border-width: 0;
    border-style: solid;
    border-radius: 20px;
    width: 100%;
    margin-right: 1em;
}


.text_container {
    font-family: "Helvetica",  Sans-serif;
    color: black;
    width: 100%;
    text-align: left;
    padding-left: 1em;
}
}
.logo_container {
    font-family: "Helvetica",  Sans-serif;
    max-width:fit-content;
    background-color: #ffff;
    border-color: #819bd3;
    border-width: 0;
    border-style: solid;
    border-radius: 20px;
    width: 50%;
    margin-right: 1em;
    display: inline-block;
}

.text_container {
    font-family: "Helvetica",  Sans-serif;
    color: black;
    width: 50%;
    text-align: left;
    display: inline-block;
} 

.logo_container p {
    color: black;
    padding-bottom: 2rem;
}

.logo_container a {
    color: #819bd3;
    padding-bottom: 5rem;
}

.coming {
    color: black;
    font-weight: bold;
}

.title {
    color: black;
    font-weight: bolder;
}

.App-link {
    color: #61dafb;
}

/* @keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}
